<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="carta.nombre"
            label="Nombre"
            :rules="[rules.req]"
            class="v-superdense-input"
            append-icon="mdi-rename"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            :items="espacios"
            :item-text="item => item.nombreEspacio"
            :item-value="item => item.idEspacio"
            filled
            hide-details="auto"
            v-model="carta.idEspacio"
            label="Espacio"
            append-icon="mdi-home-group"
            :rules="[rules.req]"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="carta.year"
            label="Año"
            append-icon="mdi-calendar-month"
            :rules="[rules.isNumber]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="carta.precio"
            label="Precio base"
            append-icon="mdi-currency-eur"
            :rules="[rules.isNumber]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="carta.idCarta" @click.stop="carta = {}">
							<v-icon left>mdi-close</v-icon>
                Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ carta.idCarta ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { req, isNumber } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
    espacios: Array,
  },
  data() {
    return {
      default: {
        nombre : null,
      },
      carta: {
        ...this.value,
      },
      rules: {
        req,
        isNumber,
      },
    };
  },
  methods: {
    async save() {
      if (!this.carta.nombre || this.carta.nombre.length == 0) this.carta.nombre = this.default.nombre;
      if (!this.$refs.form.validate()) return;
      parseFloat(this.carta.precio).toFixed(2).replaceAll(',','.')
      await axios({
        method: this.carta.idCarta ? "PUT" : "POST",
        url: `/cartas/${String(
          this.carta.idCarta ? this.carta.idCarta : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.carta,
        },
      });
      this.carta = { year: new Date().getFullYear() }
      this.$emit("reload");
    },
  },
  mounted(){
    if (!this.carta.year || isNaN(this.carta.year)) this.carta.year = new Date().getFullYear()
  }
};
</script>